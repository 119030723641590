import "./busquedaCertificado.css";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import logoHorizontal from "../../images/logoHorizontal.png";
import marcaAcreditacion from "../../images/marcaAcreditacion.jpg";
import ProductoEnCertificado from "./ProductoEnCertificado";
import Loader from "../../elementos/loader/Loader";
import { accessAPI } from "../../utils/utils.js";

export default function BusquedaCertificado() {
  const [loader, setLoader] = useState(false);
  const [mensajeError, setMensajeError] = useState();
  const [certificadoABuscar, setCertificadoABuscar] = useState();
  const [certificadoEncontrado, setCertificadoEncontrado] = useState();

  // Si es un deeplink directo a un certificado obtiene los datos
  const { certificadoTipo } = useParams();
  const { certificadoNumero } = useParams();

  const certificadoTipoRef = useRef();
  const certificadoNumeroRef = useRef();

  // Cuando carga el componente se fija si tiene especificado el tipo y el número
  useEffect(() => {
    if (certificadoTipo && certificadoNumero) {
      console.log(certificadoTipo, certificadoNumero);
      setLoader(true);
      setCertificadoABuscar({
        tipo: certificadoTipo,
        numero: certificadoNumero,
      });
    }
  }, [certificadoTipo, certificadoNumero]);

  // Cuando determina el certificado a buscar lo busca
  useEffect(() => {
    if (
      certificadoABuscar &&
      certificadoABuscar.tipo &&
      certificadoABuscar.numero
    ) {
      certificadoTipoRef.current.value = certificadoABuscar.tipo.toUpperCase();
      certificadoNumeroRef.current.value = certificadoABuscar.numero;
      setMensajeError();
      setCertificadoEncontrado();
      setLoader(true);

      accessAPI(
        "GET",
        `certificado/detalles/${certificadoABuscar.tipo}/${certificadoABuscar.numero}`,
        null,
        (respuesta) => {
          setLoader(false);
          setCertificadoEncontrado(respuesta);
        },
        (respuesta) => {
          setLoader(false);
          setMensajeError(respuesta[0]?.msg);
        }
      );
    }
  }, [certificadoABuscar]);

  // Establece los datos del certificado a buscar
  function buscarCertificado() {
    if (
      !certificadoNumeroRef.current?.value ||
      !certificadoTipoRef.current?.value
    ) {
      setMensajeError("Debe completar el tipo y número del certificado");
      return;
    }

    setCertificadoABuscar({
      tipo: certificadoTipoRef.current?.value,
      numero: certificadoNumeroRef.current?.value,
    });
  }

  return (
    <div className="seccion registro">
      <header className="flexContainer izquierda">
        <img className="logo centrado" src={logoHorizontal} alt="logo" />
      </header>
      <main className="mensajeCliente">
        <h2>Buscador de certificados</h2>
        <p>
          Ingresa el código del certificado debajo para ver los productos
          incluidos y la vigencia.
        </p>
        <div className="tarjeta centrada gris buscador">
          Certificado CP-
          <select ref={certificadoTipoRef}>
            <option>S</option>
            <option>E</option>
            <option>G</option>
          </select>
          -<input type="number" ref={certificadoNumeroRef} />
          <button className="azul relleno" onClick={buscarCertificado}>
            Buscar
          </button>
        </div>
        {mensajeError && <div className="mensajeError">{mensajeError}</div>}
        {loader && <Loader>Cargando</Loader>}
        {certificadoEncontrado && (
          <div className="certificadoEncontrado">
            <h3>
              Certificado CP-
              {certificadoEncontrado.certificadotipo.procesotipo.letra}-
              {certificadoEncontrado.numero}
            </h3>
            <div>
              Emitido a nombre de{" "}
              <span className="negrita">
                {certificadoEncontrado.cliente.razonsocial}
              </span>
            </div>
            <div>
              Norma:{" "}
              <span className="negrita">
                {certificadoEncontrado.norma.numero}:
              </span>
            </div>
            <div>
              Vencimiento:{" "}
              <span className="negrita">
                {new Date(
                  certificadoEncontrado.certificado[0].fechavencimiento
                ).getFullYear()}
                -
                {new Date(
                  certificadoEncontrado.certificado[0].fechavencimiento
                ).getMonth() + 1}
                -
                {new Date(
                  certificadoEncontrado.certificado[0].fechavencimiento
                ).getDate()}
              </span>
            </div>
            {certificadoEncontrado.norma.acreditada && (
              <div>
                <img
                  src={marcaAcreditacion}
                  className="marcaAcreditacion"
                  alt="marca acreditacion"
                />
              </div>
            )}
            <div className="alcance">Alcance:</div>
            {certificadoEncontrado.producto.map((producto, indexProducto) => {
              return (
                <ProductoEnCertificado
                  producto={producto}
                  certificadoTipo={
                    certificadoEncontrado.certificadotipo.procesotipo.letra
                  }
                  certificadoNumero={certificadoEncontrado.numero}
                  key={indexProducto}
                />
              );
            })}
          </div>
        )}
      </main>

      <footer>
        <div className="flexContainer izquierda inline botonera">
          <button
            className="texto centrado"
            onClick={() => {
              window.open("http://www.qualisfidelis.com", "_blank");
            }}
          >
            qualisfidelis.com
          </button>
          <button
            className="texto centrado"
            onClick={() => {
              window.open(
                "https://www.organismouruguayodeacreditacion.org/wp-content/uploads/2023/10/OUAIMP034-Qualis-Fidelis-Alcance-V-1.pdf",
                "_blank"
              );
            }}
          >
            Información de acreditacion
          </button>
        </div>
      </footer>
    </div>
  );
}
